import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './About';
import Service from './Service';
import Contact from './Contact';
import HeaderSection from './Header_section';
import Contact_section from './Contact_section';
import Phonepay_section from './Phonepay_section';
import Product from './Product';
import Condition from './Condition';
import Privacy from './Privacy';
import Refund from './Refund';
import Return from './Return';
import Shipping from './Shipping';
import Fresh from './Fresh';
import Grease from './Grease';
import Waste from './Waste';

function App() {
  const location = useLocation();

  // Define the paths where you want to hide specific sections
  const hideSections = ['/shipping', '/privacy', '/refund', '/return', '/condition'];

  // Check if the current path matches any of the paths where sections should be hidden
  const shouldHideSections = hideSections.includes(location.pathname.toLowerCase());

  return (
    <>
      {/* Conditionally render HeaderSection */}
      {!shouldHideSections && <HeaderSection />}
      
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<Service />} />
        <Route path='/condition' element={<Condition />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/return' element={<Return />} />
        <Route path='/shipping' element={<Shipping />} />
        <Route path='/fresh' element={<Fresh />} />
        <Route path='/grease' element={<Grease />} />
        <Route path='/waste' element={<Waste />} />
        <Route path='/contact/' element={<Contact />} />
      </Routes>

      {/* Conditionally render Contact_section and Phonepay_section */}
      {!shouldHideSections && <Contact_section />}
      {!shouldHideSections && <Phonepay_section />}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
