
import { useNavigate, useLocation } from 'react-router-dom';
function Contact_section() {
    const navigate = useNavigate();
    const location = useLocation();
    return (

        <>
            <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
                <div class="container py-5">
                    <div class="row g-5 mb-5 align-items-center" >
                        <div class="col-lg-7">
                            <div class="position-relative mx-auto">
                                <input class="form-control rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Email address to Subscribe" />
                                <button type="button" class="btn btn-secondary rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2">Subscribe</button>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="d-flex align-items-center justify-content-center justify-content-lg-end">
                                <a class="btn btn-secondary btn-md-square rounded-circle me-3" href=""><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-secondary btn-md-square rounded-circle me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-secondary btn-md-square rounded-circle me-3" href=""><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-secondary btn-md-square rounded-circle me-0" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-md-6 col-lg-4 col-xl-4">
                            <div class="footer-item d-flex flex-column">
                                <div class="footer-item mt-5">
                                    <h3 class="text-white mb-4"> <img src="LOGO.png" alt="Logo" className="me-2" style={{ height: '40px' }} />Shivarn</h3>
                                    <p class="mb-3">we specialize in delivering cutting-edge RO (Reverse Osmosis) plants designed to provide the purest water for residential, commercial, and industrial needs. Our advanced RO systems ensure the removal of harmful contaminants, heavy metals, and bacteria, guaranteeing safe and healthy water for your consumption.</p>
                                </div>
                                <div class="position-relative">
                                    <input class="form-control rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Enter your email" />
                                    <button type="button" class="btn btn-secondary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2">SignUp</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-4">
                            <div class="footer-item d-flex flex-column mt-5">
                                <h4 class="text-white mb-4">Policy</h4>
                                {/* <a href="#"><i class="fas fa-angle-right me-2"></i> Why Choose Us</a>
                                <a href="#"><i class="fas fa-angle-right me-2"></i> Free Water Bottles</a>
                                <a href="#"><i class="fas fa-angle-right me-2"></i> Water Dispensers</a>
                                <a href="#"><i class="fas fa-angle-right me-2"></i> Bottled Water Coolers</a> */}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(window.location.origin + '/#/Condition')

                                    }}
                                >
                                    <i className="fas fa-angle-right me-2"></i> Terms & Conditions
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(window.location.origin + '/#/Privacy')

                                    }}
                                >
                                    <i className="fas fa-angle-right me-2"></i> Privacy
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(window.location.origin + '/#/Refund')

                                    }}
                                >
                                    <i className="fas fa-angle-right me-2"></i> Refund
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(window.location.origin + '/#/Return')

                                    }}
                                >
                                    <i className="fas fa-angle-right me-2"></i> Return
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(window.location.origin + '/#/Shipping')

                                    }}
                                >
                                    <i className="fas fa-angle-right me-2"></i> Shipping
                                </a>
                            </div>
                        </div>
                        {/* <div class="col-md-6 col-lg-4 col-xl-4">
                        <div class="footer-item d-flex flex-column">
                            <h4 class="text-white mb-4">Business Hours</h4>
                            <div class="mb-3">
                                <h6 class="text-muted mb-0">Mon - Friday:</h6>
                                <p class="text-white mb-0">09.00 am to 07.00 pm</p>
                            </div>
                            <div class="mb-3">
                                <h6 class="text-muted mb-0">Saturday:</h6>
                                <p class="text-white mb-0">10.00 am to 05.00 pm</p>
                            </div>
                            <div class="mb-3">
                                <h6 class="text-muted mb-0">Vacation:</h6>
                                <p class="text-white mb-0">All Sunday is our vacation</p>
                            </div>
                        </div>
                    </div> */}
                        <div class="col-md-6 col-lg-4 col-xl-4">
                            <div class="footer-item d-flex flex-column mt-5">
                                <h4 class="text-white mb-4">Contact Info</h4>
                                <a href="#"><i class="fa fa-map-marker-alt me-2"></i> B-203, Gajanan Pride, Opp. Yug Residency, B/H St. Mary School, New Naroda,
                                    Ahmedabad - 382325, Gujarat, India</a>
                                <a href="mailto:info@shivarn.com"><i class="fas fa-envelope me-2"></i> info@shivarn.com</a>
                                <a href="tel:+919978070593"><i class="fas fa-phone me-2"></i> +91-9978070593</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact_section;