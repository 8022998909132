function Fresh() {
    return ( 
        <>
         <div class="container-fluid product py-5">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth:" 800px"}}>
                    <h4 class="text-uppercase text-primary">Our Products</h4>
                    <h1 class="display-3 text-capitalize mb-3">We Deliver Best Quality products.</h1>
                </div>
                <div class="row g-4 justify-content-center">
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.2s">
                        <div class="product-item">
                            <img src="img/1.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">100 LPH-Automatic RO-TDS upto 1500-Recovery 50%</a>
                                <p class="fs-4 text-primary mb-3">₹49,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.4s">
                        <div class="product-item">
                            <img src="img/3.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">200 LPH-semi-Automatic RO-TDS upto 1500-Recovery 70%</a>
                                <p class="fs-4 text-primary mb-3">₹99,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/2.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">200 LPH-semi-Automatic RO-TDS upto 3000-Recovery 50%</a>
                                <p class="fs-4 text-primary mb-3">₹104,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/3.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">200 LPH-semi-Automatic RO-TDS upto 3000-Recovery 70%</a>
                                <p class="fs-4 text-primary mb-3">₹119,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/4.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">250 LPH-Automatic RO-TDS upto 3000-Recovery 70%</a>
                                <p class="fs-4 text-primary mb-3">₹139,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/6.jpg" class="img-fluid w-100rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">250 LPH-semi-Automatic RO-TDS upto 1500-Recovery 70%</a>
                                <p class="fs-4 text-primary mb-3">₹104,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/5.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">500 LPH-semi-Automatic RO-TDS upto 3000-Recovery 70%</a>
                                <p class="fs-4 text-primary mb-3">₹159,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/5.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">500 LPH-semi-Automatic RO-TDS upto 1500-Recovery 50%</a>
                                <p class="fs-4 text-primary mb-3">₹124,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/5.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">500 LPH-Automatic RO-TDS upto 1500-Recovery 50%</a>
                                <p class="fs-4 text-primary mb-3">₹149,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/7.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">3000-LPH-fully-automatic-water-softener,inlet hardness upto 1500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹64,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/7.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">3000-LPH-fully-automatic-water-softener,inlet hardness upto 1500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹53,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/7.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">2000-LPH water-softener fully-automatic forinlet hardness upto 1500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹55,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/8.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">3000-LPH water-softener semi-automatic inlet hardness upto 1500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹48,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/8.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">2000-LPH water-softener semi-automatic forinlet hardness upto 1500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹40,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/8.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">2000-LPH water-softener semi-automatic forinlet hardness upto 500 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹29,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/9.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">100 LPH semi-Automatic iron Removal Filter</a>
                                <p class="fs-4 text-primary mb-3">₹9,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/9.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">200 LPH semi-Automatic iron Removal Filter</a>
                                <p class="fs-4 text-primary mb-3">₹12,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/9.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">300 LPH semi-Automatic iron Removal Filter</a>
                                <p class="fs-4 text-primary mb-3">₹14,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sand Filter 8000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹19,900</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sand Filter 2000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹8,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sand Filter 4000 LPH(Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹27,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/11.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Carbon Filter 2000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹10,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/11.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Carbon Filter 4000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹17,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/11.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Carbon Filter 8000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹24,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/12.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">250 LPH Washing Machine Water Softener for Inlet Hardness 400 PPM</a>
                                <p class="fs-4 text-primary mb-3">₹8,099</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/12.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">250 LPH Geyser Water Softener for Inlet Hardness 400 PPM
                                </a>
                                <p class="fs-4 text-primary mb-3">₹7,837</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/13.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Ultra filteration plant</a>
                                <p class="fs-4 text-primary mb-3">₹449,999</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sand Filter 8000 LPH(Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹32,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sand Filter 2000 LPH(Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹20,000</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" style={{padding: '10px'}} data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/10.png" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">sand Filter 4000 LPH(semi-Automatic)</a>
                                <p class="fs-4 text-primary mb-3">₹13,000</p>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
     );
}

export default Fresh;