function Waste() {
    return ( 
        <>
        <div class="container-fluid product py-5">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: "800px"}}>
                    <h4 class="text-uppercase text-primary">Our Products</h4>
                    <h1 class="display-3 text-capitalize mb-3">We Deliver Best Quality products.</h1>
                </div>
                <div class="row g-4 justify-content-center">
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="product-item">
                            <img src="img/15.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">2 KLD Semi-Automatic Industrial ETP Plant
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 150,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.4s">
                        <div class="product-item">
                            <img src="img/16.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Industrial Semi-Automatic ETP 1 KLD
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 100,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/16.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">S.B.R Semi-Automatic STP 1 KLD
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 100,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/17.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Tube Settler Media
                               
                                </a>
                                <p class="fs-4 text-primary mb-3"> ₹ 9,999</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/18.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Sludge Transfer Pump
                                
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 45,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/19.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">SS Filter Housing
                                
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 10,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/20.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">FAB media
                                
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 10,000</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/21.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">FRP resin
                                
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 39,999</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="product-item">
                            <img src="img/22.jpg" class="img-fluid w-100 rounded-top"  alt="Image"/>
                            <div class="product-content bg-light text-center rounded-bottom p-4">
                                <a href="" class="h4 d-inline-block mb-3">Screw press
                                
                                </a>
                                <p class="fs-4 text-primary mb-3">₹ 700,000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default Waste;